import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import rawMaterialsService from '../../service/api/RawMaterialsService';

import ingredientsService from '../../service/api/IngredientsService';
import { Dialog } from 'primereact/dialog';
import IngredientDialog from './IngredientDialog';
import { Trans, useTranslation } from 'react-i18next';
import { Ingredient } from '../../types/Ingredient';

import { Toast } from 'primereact/toast';
import { SETTINGS } from '../../enums/settings.enum';

import { RawMaterial } from '../../types/RawMaterial';

export const IngredientManagement = () => {
  const toast = useRef<any>();

  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [ingredients, setIngredients] = useState<Ingredient[]>(null);
  const [ingredientToDelete, setIngredientToDelete] = useState<Ingredient>();

  const [rawMaterials, setRawMaterials] = useState<RawMaterial[]>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<Ingredient>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();

  const showSaveToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('ingredients.toast_saved'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showAddToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('ingredients.toast_added'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showDeleteToast = (summary: string) => {
    toast.current.show({
      severity: 'info',
      summary: summary,
      detail: t('ingredients.toast_deleted'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showDeleteError = (summary: string) => {
    toast.current.show({
      severity: 'error',
      summary: summary,
      detail: t('ingredients.delete_error_ingredient_in_use'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const loadData = () => {
    setLoading(true);
    Promise.all([ingredientsService.getAll(), rawMaterialsService.getAll()]).then((response) => {
      setRawMaterials(response[1]);
      const rawMaterialsDict = Object.assign({}, ...response[1].map((r) => ({ [r.id]: r.name })));

      const _ingredients = response[0];
      _ingredients.forEach((_x) => {
        _x.rawMaterialName = rawMaterialsDict[_x.rawMaterialId];
      });
      setIngredients(_ingredients);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const ingredientsTableHeader = (
    <div className="table-header">
      {t('ingredients.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addIngredient();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteIngredient = (ingredient: Ingredient) => {
    setIngredientToDelete(ingredient);
    setDeleteDialogVisible(true);
  };

  const deleteIngredient = async () => {
    setLoading(true);
    try {
      await ingredientsService.delete(ingredientToDelete.id);
      loadData();

      showDeleteToast(ingredientToDelete.name);
    } catch (error: any) {
      if (error.response.status === 409) {
        showDeleteError(ingredientToDelete.name);
        setLoading(false);
      }
    }

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editIngredient = (_ingredient: Ingredient) => {
    setSelectedRowToEdit(_ingredient);
    setAddEditDialogVisible(true);
  };

  const addIngredient = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteIngredient()} />
    </>
  );

  const bodyTemplate = (data: Ingredient, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: Ingredient) => (
    <span>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip={t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editIngredient(data);
        }}
      ></Button>
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          confirmDeleteIngredient(data);
        }}
      ></Button>
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={ingredients}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('ingredients.empty_message')}
            loading={loading}
            header={ingredientsTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>
            <Column field="pinNumber" header={t('ingredients.pin_number')} sortable body={bodyTemplate}></Column>
            <Column
              field="rawMaterialName"
              header={t('ingredients.raw_material')}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <IngredientDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            ingredient={selectedRowToEdit}
            availableRawMaterials={rawMaterials}
            showAddToast={showAddToast}
            showSaveToast={showSaveToast}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {ingredientToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: ingredientToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
};
