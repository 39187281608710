import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';

import recipesService from '../../service/api/RecipesService';
import ordersService from '../../service/api/OrdersService';
import OrderDialog from './OrderDialog';
import CreateOrderDialog from './CreateOrderDialog';
import { useTranslation } from 'react-i18next';
import { Order } from '../../types/Order';

import { Toast } from 'primereact/toast';
import { SETTINGS } from '../../enums/settings.enum';

import { Recipe } from '../../types/Recipe';
import { addDays, format, parseISO } from 'date-fns';
import getPaginatorTemplate from '../_shared/getPaginatorTemplate';
import { deepCopy } from '../_shared/objectHelpers';
import { Calendar, CalendarChangeParams } from 'primereact/calendar';

export const OrderManagement = () => {
  const toast = useRef<any>();

  const [loading, setLoading] = useState(true);

  const [orders, setOrders] = useState<Order[]>(null);

  const [recipes, setRecipes] = useState<Recipe[]>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<Order>(null);
  const [viewDetailsDialogVisible, setViewDetailsDialogVisible] = useState(false);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const { t } = useTranslation();
  const [startRecipeId, setStartRecipeId] = useState<string>(null);

  const emptyPaginatorState = {
    currentPage: 1,
    totalPages: 1,
    rows: 10,
  };

  const emptyPaginationParameters = {
    order: {
      isAscending: false,
      orderColumn: '',
    },
    page: {
      index: 1,
      size: 10,
    },
  };

  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);

  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);

  const handlePageChange = (pageNumber) => {
    setPaginatorState((prevState) => ({ ...prevState, currentPage: pageNumber }));
    setPaginationParameters((prevState) => {
      const clone = deepCopy(prevState);
      clone.page.index = pageNumber;
      return clone;
    });
  };

  const handleRowsPerPageChange = (rowsPerPage) => {
    setPaginatorState((prevState) => ({ ...prevState, currentPage: 1, rows: rowsPerPage }));
    setPaginationParameters((prevState) => {
      const clone = deepCopy(prevState);
      clone.page.index = 1;
      clone.page.size = rowsPerPage;
      return clone;
    });
  };

  const showAddToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('orders.toast_added'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const loadData = async () => {
    setLoading(true);

    const ordersResponse = await ordersService.search(paginationParameters, {
      startDate: startDate ? format(startDate, SETTINGS.API_DATE_FORMAT) : null,
      endDate: endDate ? format(addDays(endDate, 1), SETTINGS.API_DATE_FORMAT) : null,
    });
    const recipes = await recipesService.getAll();

    setRecipes(recipes);
    const recipesDict = Object.assign({}, ...recipes.map((r) => ({ [r.id]: r.name })));

    setPaginatorState((prevStat) => {
      return {
        ...prevStat,
        totalPages: ordersResponse.totalPageNumber,
        currentPage: prevStat.currentPage > ordersResponse.totalPageNumber ? 1 : prevStat.currentPage,
      };
    });
    const orders = ordersResponse.data;

    orders.forEach((_x) => {
      _x.recipeName = recipesDict[_x.recipeId];
    });
    setOrders(orders);

    setLoading(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationParameters]);

  const ordersTableHeader = (
    <div className="table-header">
      {t('orders.header')}
      <span className="p-input-icon-left">
        <Calendar
          placeholder={t('common.date_from')}
          locale="pl"
          inputId="startDate"
          value={startDate}
          className={`mr-1`}
          onChange={(element: CalendarChangeParams) => {
            setStartDate(element?.value as Date);
            handlePageChange(1);
          }}
          showIcon
        />

        <Calendar
          placeholder={t('common.date_to')}
          locale="pl"
          inputId="endDate"
          value={endDate}
          className={`mr-1`}
          onChange={(element: CalendarChangeParams) => {
            setEndDate(element?.value as Date);
            handlePageChange(1);
          }}
          showIcon
        />
      </span>
    </div>
  );

  const closeAddModal = () => {
    setAddDialogVisible(false);
    loadData();
  };

  const closeDetailsModal = () => {
    setViewDetailsDialogVisible(false);
    loadData();
  };

  const viewOrder = async (_order: Order) => {
    const order = await ordersService.get(_order.id); // we need to get all the details, including ingredients
    setSelectedRowToEdit(order);
    setViewDetailsDialogVisible(true);
  };

  const addOrder = (recipeId: string) => {
    setStartRecipeId(recipeId);
    setAddDialogVisible(true);
  };

  const resumeOrder = async (orderId: string) => {
    await ordersService.resume(orderId);
    loadData();
  };

  const pauseOrder = async (orderId: string) => {
    await ordersService.pause(orderId);
    loadData();
  };

  const stopOrder = async (orderId: string) => {
    await ordersService.stop(orderId);
    loadData();
  };

  const bodyTemplate = (data: Order, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const statusTemplate = (data: Order, props: ColumnBodyOptions) => {
    return <>{t(`orders.statuses.${data[props.field]}`)}</>;
  };

  const dateTemplate = (data: Order, props: ColumnBodyOptions): JSX.Element => {
    const field = data[props.field];
    if (!field) {
      return <></>;
    }

    return <>{format(parseISO(field), t('formats.full_format'))}</>;
  };

  const actionTemplate = (data: Order) => (
    <span>
      {['FINISHED', 'CANCELLED'].includes(data.status) ? (
        <>
          <Button
            type="button"
            icon="pi pi-eye"
            tooltip={t('common.preview')}
            tooltipOptions={{ position: 'top' }}
            className="p-button-success mr-2"
            onClick={() => {
              viewOrder(data);
            }}
          ></Button>
          <Button
            type="button"
            icon="pi pi-refresh"
            tooltip={t('common.redo')}
            tooltipOptions={{ position: 'top' }}
            className="p-button-success mr-2"
            onClick={() => {
              addOrder(data.recipeId);
            }}
          ></Button>
        </>
      ) : (
        <></>
      )}
      {data.status === 'PAUSED' ? (
        <Button
          type="button"
          icon="pi pi-play"
          tooltip={t('common.resume')}
          tooltipOptions={{ position: 'top' }}
          className="p-button-success mr-2"
          onClick={() => {
            resumeOrder(data.id);
          }}
        ></Button>
      ) : (
        <></>
      )}
      {data.status === 'IN_PROGRESS' ? (
        <Button
          type="button"
          icon="pi pi-pause"
          tooltip={t('common.pause')}
          tooltipOptions={{ position: 'top' }}
          className="p-button-success mr-2"
          onClick={() => {
            pauseOrder(data.id);
          }}
        ></Button>
      ) : (
        <></>
      )}
      {['IN_PROGRESS', 'PAUSED'].includes(data.status) ? (
        <Button
          type="button"
          icon="pi pi-stop"
          tooltip={t('common.stop')}
          tooltipOptions={{ position: 'top' }}
          className="p-button-success mr-2"
          onClick={() => {
            stopOrder(data.id);
          }}
        ></Button>
      ) : (
        <></>
      )}
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={orders}
            paginator
            className="p-datatable-customers"
            rows={paginatorState.rows}
            dataKey="id"
            rowHover
            emptyMessage={t('orders.empty_message')}
            loading={loading}
            header={ordersTableHeader}
            paginatorTemplate={getPaginatorTemplate(paginatorState, handlePageChange, handleRowsPerPageChange, null)}
          >
            <Column field="name" header={t('common.name')} body={bodyTemplate}></Column>
            <Column field="recipeName" header={t('orders.recipe')} body={bodyTemplate}></Column>
            <Column field="status" header={t('orders.status')} body={statusTemplate}></Column>
            <Column field="startDate" header={t('orders.start_date')} body={dateTemplate}></Column>
            <Column field="endDate" header={t('orders.end_date')} body={dateTemplate}></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <OrderDialog
            dialogVisible={viewDetailsDialogVisible}
            closeAddEditModal={closeDetailsModal}
            order={selectedRowToEdit}
            availableRecipes={recipes}
          />
          <CreateOrderDialog
            dialogVisible={addDialogVisible}
            closeAddEditModal={closeAddModal}
            recipeId={startRecipeId}
            showAddToast={showAddToast}
          />
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
};
