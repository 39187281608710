import { useState, useEffect, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { Redirect, Route, useHistory } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';

import AppMenu from './AppMenu';
import AppInlineProfile from './AppInlineProfile';

import { addLocale, locale } from 'primereact/api';

import { AxiosConfigContainer } from './components/AxiosConfig';
import { useTranslation } from 'react-i18next';

import Measurements from './components/Measurements';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from './store/user-slice';
import usersService from './service/api/UsersService';

import { ChangesOfStates } from './components/Scales/ChangesOfStates/ChangesOfStates';
import { ScaleChart } from './components/Scales/Chart/Chart';

import { ReceptionsRecords } from './components/Suppliers/Receptions';

import { ScalesIndications } from './components/ScalesIndications/ScalesIndications';
import { AutoWeighings } from './components/Scales/AutoWeighings/AutoWeighings';
import { WeighingsList } from './components/WeighingsList/WeighingsList';
import { ScaleManagement } from './components/Configuration/ScaleManagement/ScaleManagement';
import { HostManagement } from './components/Configuration/HostManagement/HostManagement';
import NotFound from './pages/NotFound';
import { modeContext } from './services/ModeContext';
import { DivisionManagement } from './components/Configuration/Divisions/DivisionManagement';
import UserDialog from './components/Users/UserDialog';
import ChangePasswordDialog from './components/Users/ChangePasswordDialog';
import { OpenMeasurementsSeries } from './components/Scales/MeasurementsSeries/OpenMeasurementsSeries';
import { ClosedMeasurementsSeries } from './components/Scales/MeasurementsSeries/ClosedMeasurementsSeries';
import { MeasurementsSeriesDetails } from './components/Scales/MeasurementsSeries/MeasurementsSeriesDetails';
import { RoleManagement } from './components/Roles/RoleManagement';
import { hasOneOfPermissions, hasPermission } from './components/_shared/hasPermission';
import { UserManagement } from './components/Users/UserManagement';
import divisionsService from './service/api/DivisionsService';
import { AnimalManagement } from './components/Animals/AnimalManagement';
import { AnimalDetailsPage } from './components/Animals/AnimalDetailsPage';
import { SETTINGS } from './enums/settings.enum';
import { CommunicationMode } from './enums/communication-mode.enum';
import { RootState } from './store';
import { PrinterManagement } from './components/Printers/PrinterManagement';
import { RawMaterialManagement } from './components/rawMaterial/RawMaterialManagement';
import { IngredientManagement } from './components/ingredient/IngredientManagement';
import { RecipeManagement } from './components/recipe/RecipeManagement';
import { OrderManagement } from './components/order/OrderManagement';
import { RawMaterialReportPage } from './components/rawMaterial/RawMaterialReportPage';
// <%-appJsImport%>

addLocale('pl', {
  accept: 'Tak',
  reject: 'Nie',
  choose: 'Wybierz',
  upload: 'Wyślij',
  cancel: 'Anuluj',
  dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
  dayNamesShort: ['niedz.', 'pon.', 'wt.', 'śr.', 'czw.', 'pt.', 'sob.'],
  dayNamesMin: ['niedz.', 'pon.', 'wt.', 'śr.', 'czw.', 'pt.', 'sob.'],
  monthNames: [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień',
  ],
  monthNamesShort: ['STY', 'LUT', 'MAR', 'KWI', 'MAJ', 'CZE', 'LIP', 'SIE', 'WRZ', 'PAŹ', 'LIS', 'GRU'],
  today: 'Dziś',
  clear: 'Wyczyść',
  weekHeader: 'tydz.',
  firstDayOfWeek: 0,
  weak: 'Słaby',
  medium: 'Umiarkowany',
  strong: 'Silny',
  passwordPrompt: 'Podaj hasło',
  dateFormat: 'dd.mm.yy',
});

locale('pl');

const modeCtx = modeContext();

const App = () => {
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [topbarMenuActive, setTopbarMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [inlineMenuActive, setInlineMenuActive] = useState(false);
  const [userDialogVisible, setUserDialogVisible] = useState(false);
  const [changePasswordDialogVisible, setChangePasswordDialogVisible] = useState(false);

  const [divisions, setDivisions] = useState(null);

  const [configActive, setConfigActive] = useState(false);
  const dispatch = useDispatch();
  const loggedUserContext = useSelector((state: RootState) => state.user.context);
  const [initialized, setInitialized] = useState(false);
  const history = useHistory();

  const { i18n, t } = useTranslation();

  const profileMode = 'popup';
  const ripple = false;
  const menuActive = false;
  const menuMode = 'static';
  const darkMenu = true;

  const fetchUserContext = useCallback(async () => {
    const response = await usersService.getLoggedUserContext();
    const context = response.data;
    dispatch(userActions.setLoggedUserContext(context));

    if (hasPermission('CHANGE_DIVISION', context.permissions)) {
      const result = await divisionsService.getAllDivisionsInTheSystem();
      setDivisions(result);
    }

    setInitialized(true);
  }, [dispatch]);

  useEffect(() => {
    fetchUserContext();
  }, [fetchUserContext]);

  const setLanguage = useCallback(() => {
    i18n.changeLanguage('pl');
  }, [i18n]);

  useEffect(() => {
    setLanguage();
  }, [setLanguage]);

  let menuClick = false;
  let configClick = false;
  let topbarItemClick = false;
  let inlineMenuClick = false;

  const scaleMenuItems = loggedUserContext.scales.map((element) => {
    return {
      label: element?.name,
      icon: 'pi pi-fw pi-filter',
      items: [
        {
          label: t('app_labels.scale_chart'),
          icon: 'pi pi-fw pi-chart-bar',
          to: `/${element?.id}/scale-chart`,
        },
        {
          label: t('app_labels.auto_weighings'),
          icon: 'pi pi-fw pi-tablet',
          to: `/${element?.id}/auto-weighings`,
          visible: element.communicationMode === CommunicationMode.TRIGGERED_BY_US,
        },
        {
          label: t('measurementsSeries.header'),
          icon: 'pi pi-fw pi-chart-bar',
          visible: element.communicationMode === CommunicationMode.TRIGGERED_BY_US,
          items: [
            {
              label: t('measurementsSeries.open'),
              icon: 'pi pi-fw pi-chart-bar',
              to: `/${element?.id}/measurements-series/open`,
            },
            {
              label: t('measurementsSeries.closed'),
              icon: 'pi pi-fw pi-chart-bar',
              to: `/${element?.id}/measurements-series/closed`,
            },
          ],
        },
        {
          label: t('app_labels.changes_of_states'),
          icon: 'pi pi-fw pi-bars',
          to: `/${element?.id}/changes-of-states`,
          visible: element.communicationMode === CommunicationMode.TRIGGERED_BY_US,
        },
      ],
    };
  });

  const getByScaleId = (scaleId) => {
    for (const scale of loggedUserContext.scales) {
      if (scaleId === scale.id) {
        return scale;
      }
    }
  };

  const menu = [
    {
      icon: 'pi pi-align-left',
      items: [
        {
          label: t('users.header'),
          icon: 'pi pi-align-left',
          to: '/users',
          visible: hasPermission('USER_MANAGEMENT', loggedUserContext.permissions),
        },
      ],
    },
    {
      icon: 'pi pi-align-left',
      items: [
        {
          label: t('roles.header'),
          icon: 'pi pi-align-left',
          to: '/roles',
          visible: hasPermission('ROLE_MANAGEMENT', loggedUserContext.permissions),
        },
      ],
    },
    {
      icon: 'pi pi-fw pi-align-left',
      items: [
        {
          label: t('app_labels.scales_indications'),
          icon: 'pi pi-fw pi-chart-bar',
          to: `/scales-indications`,
        },
      ],
    },
    {
      icon: 'pi pi-fw pi-align-left',
      items: [
        {
          label: t('app_labels.weighing_list'),
          icon: 'pi pi-fw pi-chart-bar',
          to: `/weighing-list`,
        },
      ],
    },
    {
      label: t('app_labels.scales'),
      icon: 'pi pi-fw pi-align-left',
      items: scaleMenuItems,
    },
    {
      label: t('app_labels.config'),
      icon: 'pi pi-fw pi-align-left',
      items: [
        {
          label: t('hosts.header'),
          icon: 'pi pi-fw pi-cog',
          to: '/hosts',
          visible: modeCtx.hostsEnabled && hasPermission('HOST_MANAGEMENT', loggedUserContext.permissions),
        },
        {
          label: t('scales.header'),
          icon: 'pi pi-fw pi-cog',
          to: '/scales',
          visible: hasPermission('SCALE_MANAGEMENT', loggedUserContext.permissions),
        },
        {
          label: t('printers.header'),
          icon: 'pi pi-fw pi-cog',
          to: '/printers',
          visible: hasPermission('PRINTER_MANAGEMENT', loggedUserContext.permissions),
        },
        {
          label: t('divisions.header'),
          icon: 'pi pi-fw pi-cog',
          to: '/divisions',
          visible: hasPermission('DIVISION_MANAGEMENT', loggedUserContext.permissions),
        },
      ],
    },
    {
      label: t('app_labels.dosage'),
      icon: 'pi pi-fw pi-align-left',
      visible: hasOneOfPermissions(
        ['ORDER_MANAGEMENT', 'RECIPE_MANAGEMENT', 'INGREDIENT_MANAGEMENT', 'RAW_MATERIAL_MANAGEMENT'],
        loggedUserContext.permissions
      ),
      items: [
        {
          label: t('orders.header'),
          icon: 'pi pi-fw pi-bars',
          to: '/order',
          visible: hasPermission('ORDER_MANAGEMENT', loggedUserContext.permissions),
        },
        {
          label: t('recipes.header'),
          icon: 'pi pi-fw pi-bars',
          to: '/recipe',
          visible: hasPermission('RECIPE_MANAGEMENT', loggedUserContext.permissions),
        },
        {
          label: t('ingredients.header'),
          icon: 'pi pi-fw pi-bars',
          to: '/ingredient',
          visible: hasPermission('INGREDIENT_MANAGEMENT', loggedUserContext.permissions),
        },
        {
          label: t('rawMaterials.header'),
          icon: 'pi pi-fw pi-bars',
          to: '/raw-material',
          visible: hasPermission('RAW_MATERIAL_MANAGEMENT', loggedUserContext.permissions),
        },
      ],
    },
    {
      label: t('app_labels.reports'),
      icon: 'pi pi-fw pi-align-left',
      visible: hasPermission('VIEW_DOSAGE_REPORTS', loggedUserContext.permissions),
      items: [
        {
          label: t('rawMaterials.report_header'),
          icon: 'pi pi-fw pi-bars',
          to: '/raw-material-report',
          visible: hasPermission('VIEW_DOSAGE_REPORTS', loggedUserContext.permissions),
        },
      ],
    },
    {
      icon: 'pi pi-fw pi-align-left',
      items: [
        {
          label: t('app_labels.animals'),
          icon: 'pi pi-fw pi-bars',
          to: `/animals`,
          visible: hasPermission('ANIMAL_MANAGEMENT', loggedUserContext.permissions),
        },
        // <%-appJsMenu%>
      ],
    },
  ];

  const routers = [
    {
      path: '/scales-indications',
      render: () => {
        return <ScalesIndications scales={loggedUserContext.scales} />;
      },
      meta: {
        breadcrumb: [
          {
            label: t('app_labels.scales_indications'),
          },
        ],
      },
    },
    {
      path: '/weighing-list',
      render: () => {
        return <WeighingsList scales={loggedUserContext.scales} />;
      },
      meta: {
        breadcrumb: [
          {
            label: t('app_labels.scales_indications'),
          },
        ],
      },
    },
    {
      path: '/users',
      exact: false,
      render: () => <UserManagement />,
      meta: {
        breadcrumb: [{}],
      },
    },
    {
      path: '/roles',
      render: () => <RoleManagement />,
      meta: {
        breadcrumb: [{}],
      },
    },
    { path: '/:scaleId/measurements', component: Measurements },
    {
      path: '/:scaleId/changes-of-states',
      render: (e: any) => {
        let scaleName = getByScaleId(e.match?.params?.scaleId)?.name;

        let weighingUnit = getByScaleId(e.match?.params?.scaleId)?.weighingUnit;

        if ((scaleName || scaleName === '') && (weighingUnit || weighingUnit === '')) {
          return <ChangesOfStates scaleName={scaleName} weighingUnit={weighingUnit.toLowerCase()} />;
        }

        return <NotFound />;
      },
      meta: {
        breadcrumb: [
          {
            parent: t('app_labels.scales'),
            label: t('app_labels.changes_of_states'),
          },
        ],
      },
    },
    {
      path: '/:scaleId/scale-chart',
      render: (e: any) => {
        const scale = getByScaleId(e.match?.params?.scaleId);
        return scale ? <ScaleChart scaleId={scale.id} scaleName={scale.name} /> : <NotFound />;
      },

      meta: {
        breadcrumb: [
          {
            parent: t('app_labels.scales'),
            label: t('app_labels.scale_chart'),
          },
        ],
      },
    },
    {
      path: '/:scaleId/auto-weighings',
      render: (e: any) => {
        const scaleName = getByScaleId(e.match?.params?.scaleId)?.name;

        return scaleName || scaleName === '' ? <AutoWeighings scaleName={scaleName} /> : <NotFound />;
      },
      meta: {
        breadcrumb: [
          {
            parent: t('app_labels.scales'),
            label: t('app_labels.auto_weighings'),
          },
        ],
      },
    },
    {
      path: '/:scaleId/measurements-series/open',
      render: (e: any) => {
        const scale = getByScaleId(e.match?.params?.scaleId);

        return scale ? <OpenMeasurementsSeries scaleId={scale.id} scaleName={scale.name} /> : <NotFound />;
      },
      meta: {
        breadcrumb: [
          {
            parent: t('app_labels.scales'),
            label: t('measurementsSeries.open'),
          },
        ],
      },
    },
    {
      path: '/:scaleId/measurements-series/closed',
      render: (e: any) => {
        const scale = getByScaleId(e.match?.params?.scaleId);

        return scale ? <ClosedMeasurementsSeries scaleId={scale.id} scaleName={scale.name} /> : <NotFound />;
      },
      meta: {
        breadcrumb: [
          {
            parent: t('app_labels.scales'),
            label: t('measurementsSeries.open'),
          },
        ],
      },
    },
    {
      path: '/measurements-series/:seriesId/details',
      render: (e: any) => <MeasurementsSeriesDetails seriesId={e.match?.params?.seriesId} />,
    },
    {
      path: '/suppliers-records',
      render: () => <ReceptionsRecords />,
      meta: {
        breadcrumb: [
          {
            parent: t('app_labels.suppliers'),
            label: t('app_labels.suppliers_list'),
          },
        ],
      },
    },
    {
      path: '/hosts',
      render: () => {
        return modeCtx.hostsEnabled ? <HostManagement /> : <NotFound />;
      },
    },
    {
      path: '/scales',
      render: () => {
        return <ScaleManagement reloadMenu={fetchUserContext} />;
      },
    },
    {
      path: '/printers',
      render: () => {
        return <PrinterManagement />;
      },
    },
    {
      path: '/divisions',
      render: () => {
        return <DivisionManagement />;
      },
    },
    {
      path: '/animal-details/:id',
      render: () => {
        return <AnimalDetailsPage />;
      },
    },
    {
      path: '/animals',
      render: () => {
        return <AnimalManagement />;
      },
    },
    {
      path: '/raw-material',
      component: RawMaterialManagement,
      render: () => {
        return <RawMaterialManagement />;
      },
    },
    {
      path: '/raw-material-report',
      component: RawMaterialReportPage,
      render: () => {
        return <RawMaterialReportPage />;
      },
    },
    {
      path: '/ingredient',
      component: IngredientManagement,
      render: () => {
        return <IngredientManagement />;
      },
    },
    {
      path: '/recipe',
      component: RecipeManagement,
      render: () => {
        return <RecipeManagement />;
      },
    },
    {
      path: '/order',
      component: OrderManagement,
      render: () => {
        return <OrderManagement />;
      },
    },
    // <%-appJsRouters%>
  ];

  const onDocumentClick = () => {
    if (!topbarItemClick) {
      setActiveTopbarItem(null);
      setTopbarMenuActive(false);
    }

    if (!menuClick) {
      hideOverlayMenu();
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    inlineMenuClick = false;
    configClick = false;
    topbarItemClick = false;
    menuClick = false;
  };

  const onMenuitemClick = (event) => {
    if (!event.item.items) {
      hideOverlayMenu();
    }
  };

  const onMenuClick = () => {
    menuClick = true;

    if (inlineMenuActive && !inlineMenuClick) {
      setInlineMenuActive(false);
    }
  };

  const isMenuVisible = () => {
    if (isDesktop()) {
      if (menuMode === 'static') return !staticMenuDesktopInactive;
      else if (menuMode === 'overlay') return overlayMenuActive;
      else return true;
    } else {
      return true;
    }
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;
    setTopbarMenuActive(false);

    if (isOverlay() && !isMobile()) {
      setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
    } else {
      if (isDesktop()) {
        setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
      } else {
        setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
      }
    }

    event.preventDefault();
  };

  const onProfileButtonClick = () => {
    setInlineMenuActive((prevInlineMenuActive) => !prevInlineMenuActive);
    inlineMenuClick = true;
  };

  const onTopbarMenuButtonClick = (event) => {
    topbarItemClick = true;
    setTopbarMenuActive((prevTopbarMenuActive) => !prevTopbarMenuActive);

    hideOverlayMenu();

    event.preventDefault();
  };

  const onTopbarItemClick = (event, item) => {
    topbarItemClick = true;

    if (activeTopbarItem === item) {
      setActiveTopbarItem(null);
    } else {
      setActiveTopbarItem(item);
    }

    event.preventDefault();
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const isDesktop = () => {
    return window.innerWidth > 896;
  };

  const isMobile = () => {
    return window.innerWidth <= 896;
  };

  const isOverlay = () => {
    return false;
  };

  const isHorizontal = () => {
    return false;
  };

  const isSlim = () => {
    return false;
  };

  const isStatic = () => {
    return menuMode === 'static';
  };

  const hasInlineProfile = false;

  const containerClassName = classNames('layout-wrapper', {
    'layout-static': isStatic(),
    'layout-overlay': isOverlay(),
    'layout-overlay-active': overlayMenuActive,
    'layout-horizontal': isHorizontal(),
    'layout-slim': isSlim(),
    'layout-static-inactive': staticMenuDesktopInactive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-menu-dark': darkMenu,
    'layout-menu-light': !darkMenu,
    'p-input-filled': false,
    'p-ripple-disabled': !ripple,
  });

  const menuContainerClassName = classNames('layout-menu-container', {
    'layout-menu-container-inactive': !isMenuVisible(),
  });

  const showUserDialog = () => {
    setUserDialogVisible(true);
  };

  const hideUserDialog = () => {
    setUserDialogVisible(false);
    fetchUserContext();
  };

  const showChangePasswordDialog = () => {
    setChangePasswordDialogVisible(true);
  };

  const hideChangePasswordDialog = () => {
    setChangePasswordDialogVisible(false);
  };

  return (
    <div className={containerClassName} onClick={onDocumentClick}>
      <AxiosConfigContainer />
      {initialized && (
        <>
          <AppTopbar
            topbarMenuActive={topbarMenuActive}
            activeTopbarItem={activeTopbarItem}
            onMenuButtonClick={onMenuButtonClick}
            onTopbarMenuButtonClick={onTopbarMenuButtonClick}
            onTopbarItemClick={onTopbarItemClick}
            onShowUserDetailsClick={showUserDialog}
            onChangePasswordClick={showChangePasswordDialog}
            isHorizontal={isHorizontal()}
            profileMode={profileMode}
            isMobile={isMobile}
            allDivisions={divisions}
            divisionIds={loggedUserContext.divisionIds}
            onDivisionChange={async (ids) => {
              await divisionsService.reassign(ids);
              fetchUserContext();
            }}
          />

          <div className={menuContainerClassName} onClick={onMenuClick}>
            <div className="layout-menu-logo">
              <button className="p-link" onClick={() => history.push(SETTINGS.DEFAULT_PAGE)}>
                <img
                  id="layout-menu-logo"
                  src="assets/layout/images/logo-white.png"
                  alt="application logo"
                  width="220"
                />
              </button>
            </div>
            <div className="layout-menu-wrapper">
              <div className="menu-scroll-content">
                {hasInlineProfile && (
                  <AppInlineProfile inlineMenuActive={inlineMenuActive} onProfileButtonClick={onProfileButtonClick} />
                )}
                <AppMenu
                  model={menu}
                  menuMode={menuMode}
                  active={menuActive}
                  onMenuitemClick={onMenuitemClick}
                  onRootMenuitemClick={() => {}}
                />
              </div>
            </div>
          </div>

          <div className="layout-main">
            <div className="layout-content">
              {routers.map((router, index) => {
                if (router.exact) {
                  return <Route key={`router${index}`} path={router.path} exact render={router.render} />;
                }

                return <Route key={`router${index}`} path={router.path} render={router.render} />;
              })}

              <Route exact path="/">
                <Redirect to={SETTINGS.DEFAULT_PAGE as any} />
              </Route>
            </div>

            <UserDialog dialogVisible={userDialogVisible} closeAddEditModal={hideUserDialog} user={loggedUserContext} />

            <ChangePasswordDialog
              dialogVisible={changePasswordDialogVisible}
              closeAddEditModal={hideChangePasswordDialog}
            />

            <AppFooter />
          </div>

          {staticMenuMobileActive && <div className="layout-mask"></div>}
        </>
      )}
    </div>
  );
};

export default App;
