import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation, withRouter } from 'react-router-dom';
import App from './App';
import Access from './pages/Access';
import Error from './pages/Error';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import { RootState } from './store';
import { LocationState } from './types/LocationState';
const AppWrapper = (): JSX.Element => {
  let location = useLocation<LocationState>();

  // eslint-disable-next-line
  const loggedUser = useSelector((state: RootState) => state.user.loggedUser);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  switch (location.pathname) {
    case '/':
      return (
        <Route path="/">
          {loggedUser && <App />}
          {!loggedUser && <Redirect to="/login" />}
        </Route>
      );
    case '/login':
      return <Route path="/login" render={() => <Login />} />;
    case '/error':
      return <Route path="/error" component={Error} />;
    case '/notfound':
      return <Route path="/notfound" component={NotFound} />;
    case '/access':
      return <Route path="/access" component={Access} />;
    default:
      return <App />;
  }
};

export default withRouter(AppWrapper);
