import { classNames } from 'primereact/utils';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar } from 'primereact/avatar';
import { useTranslation } from 'react-i18next';
import AuthContext from './store/auth-context';
import { useContext } from 'react';
import { RootState } from './store';
import { MultiSelect } from 'primereact/multiselect';
import { SETTINGS } from './enums/settings.enum';

const AppTopbar = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const authCtx = useContext(AuthContext);

  const loggedUserContext = useSelector((state: RootState) => state.user.context);

  const onLogoutClicked = () => {
    authCtx.logout();
  };

  const topbarMenuClassName = classNames('topbar-menu fadeInDown', {
    'topbar-menu-visible': props.topbarMenuActive,
  });

  const activeTopbarItemClassName = (name: string) => {
    return name === props.activeTopbarItem ? 'active-topmenuitem' : null;
  };

  const getAvatarLabel = () => {
    const initials = [loggedUserContext?.firstName?.charAt(0), loggedUserContext?.lastName?.charAt(0)].join('');
    if (initials) {
      return initials.toUpperCase();
    }

    return loggedUserContext?.email?.charAt(0)?.toUpperCase() ?? 'U';
  };

  const allDivisions = props.allDivisions?.map((division) => {
    const users = division.users
      ?.filter((u) => u.type === 'owner')
      .map((u) => u.email)
      ?.join(',');
    return { label: `${users}: ${division.name}`, value: division.id };
  });

  const handleDivisionChange = async (e) => {
    await props.onDivisionChange(e.value);
  };

  const divisionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <span className={`mr-2`} />
        <span>{option.label}</span>
      </div>
    );
  };

  const selectedDivisionTemplate = (divisionId: string) => {
    if (divisionId) {
      const division = allDivisions.find((d) => d.value === divisionId);
      return (
        <div className="inline-flex align-items-center font-bold border-round mr-2">
          <span>{division.label}</span>
        </div>
      );
    }

    return t('divisions.available_divisions');
  };

  return (
    <div className="layout-topbar">
      <button className="layout-topbar-logo p-link" onClick={() => history.push(SETTINGS.DEFAULT_PAGE)}>
        <img id="layout-topbar-logo" src="assets/layout/images/logo-white.png" alt="application logo" />
      </button>

      <button className="layout-menu-button p-link" onClick={props.onMenuButtonClick}>
        <i className="pi pi-bars"></i>
      </button>
      {allDivisions && allDivisions.length > 1 && (
        <MultiSelect
          value={props.divisionIds}
          onChange={handleDivisionChange}
          options={allDivisions}
          filter
          itemTemplate={divisionTemplate}
          selectedItemTemplate={selectedDivisionTemplate}
          className={`col-4`}
          maxSelectedLabels={1}
          selectedItemsLabel={`${t('divisions.selected_divisions')}: ${props.divisionIds?.length}`}
        />
      )}

      <button id="topbar-menu-button" className="p-link" onClick={props.onTopbarMenuButtonClick}>
        <i className="pi pi-ellipsis-v"></i>
      </button>

      <ul className={topbarMenuClassName}>
        <li className={activeTopbarItemClassName('profile')}>
          <button className="p-link" onClick={(e) => props.onTopbarItemClick(e, 'profile')}>
            <Avatar label={getAvatarLabel()} size="large" shape="circle"></Avatar>
          </button>

          <ul className="fadeInDown">
            <li role="menuitem">
              <button className="p-link" onClick={props.onShowUserDetailsClick}>
                <i className="pi pi-fw pi-user"></i>
                <span>{t('users.user_details')}</span>
              </button>
            </li>
            <li role="menuitem">
              <button className="p-link" onClick={props.onChangePasswordClick}>
                <i className="pi pi-fw pi-undo"></i>
                <span>{t('users.change_password')}</span>
              </button>
            </li>
            <li role="menuitem">
              <button className="p-link" onClick={onLogoutClicked}>
                <i className="pi pi-fw pi-sign-out"></i>
                <span>{t('users.log_out')}</span>
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AppTopbar;
