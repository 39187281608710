export const modeContext = () => {
  const isOffline = process.env.REACT_APP_OFFLINE === 'true';

  return {
    registerEnabled: false,
    passwordRecoveryEnabled: !isOffline,
    hostsEnabled: !isOffline,
    adminSetPasswordEnabled: isOffline,
  };
};
