import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import recipesService from '../../service/api/RecipesService';
import { Dialog } from 'primereact/dialog';
import RecipeDialog from './RecipeDialog';
import { Trans, useTranslation } from 'react-i18next';
import { Recipe } from '../../types/Recipe';

import { Toast } from 'primereact/toast';
import { SETTINGS } from '../../enums/settings.enum';
import ingredientsService from '../../service/api/IngredientsService';
import { Ingredient } from '../../types/Ingredient';
import CreateOrderDialog from '../order/CreateOrderDialog';

export const RecipeManagement = () => {
  const toast = useRef<any>();

  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [recipes, setRecipes] = useState<Recipe[]>(null);
  const [recipeToDelete, setRecipeToDelete] = useState<Recipe>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<Recipe>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const [startOrderDialogVisible, setStartOrderDialogVisible] = useState(false);
  const [startRecipeId, setStartRecipeId] = useState<string>(null);
  const [ingredients, setIngredients] = useState<Ingredient[]>();
  const { t } = useTranslation();

  const showSaveToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('recipes.toast_saved'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showAddToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('recipes.toast_added'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showOrderAddedToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('orders.toast_added'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showDeleteToast = (summary: string) => {
    toast.current.show({
      severity: 'info',
      summary: summary,
      detail: t('recipes.toast_deleted'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const loadData = async () => {
    setLoading(true);
    setRecipes(await recipesService.getAll());
    setIngredients(await ingredientsService.getAll());
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const recipesTableHeader = (
    <div className="table-header">
      {t('recipes.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addRecipe();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const closeStartOrderModal = () => {
    setStartOrderDialogVisible(false);
    loadData();
  };

  const startOrder = (id: string) => {
    setStartRecipeId(id);
    setStartOrderDialogVisible(true);
  };

  const confirmDeleteRecipe = (recipe: Recipe) => {
    setRecipeToDelete(recipe);
    setDeleteDialogVisible(true);
  };

  const deleteRecipe = () => {
    setLoading(true);
    recipesService.delete(recipeToDelete.id).then(() => {
      loadData();

      showDeleteToast(recipeToDelete.name);
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editRecipe = async (_recipe: Recipe) => {
    const recipe = await recipesService.get(_recipe.id); // we need to get all the details, including ingredients
    setSelectedRowToEdit(recipe);
    setAddEditDialogVisible(true);
  };

  const addRecipe = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteRecipe()} />
    </>
  );

  const bodyTemplate = (data: Recipe, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: Recipe) => (
    <span>
      <Button
        type="button"
        icon="pi pi-play"
        tooltip={t('common.start')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          startOrder(data.id);
        }}
      ></Button>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip={t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editRecipe(data);
        }}
      ></Button>
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          confirmDeleteRecipe(data);
        }}
      ></Button>
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={recipes}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('recipes.empty_message')}
            loading={loading}
            header={recipesTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>

            <Column
              headerStyle={{ width: '12rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <RecipeDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            recipe={selectedRowToEdit}
            availableIngredients={ingredients}
            showAddToast={showAddToast}
            showSaveToast={showSaveToast}
          />
          <CreateOrderDialog
            dialogVisible={startOrderDialogVisible}
            closeAddEditModal={closeStartOrderModal}
            recipeId={startRecipeId}
            showAddToast={showOrderAddedToast}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {recipeToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: recipeToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
};
