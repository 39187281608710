import axios from 'axios';

class DivisionsService {
  async getAll() {
    const result = await axios.get('/divisions');
    return result.data.map(mapFromResponse);
  }

  async reassign(divisionIds: string[]): Promise<void> {
    return axios.put('/divisions/reassign', divisionIds);
  }

  async getAllDivisionsInTheSystem() {
    const result = await axios.get('/divisions/all');
    return result.data.map(mapFromResponse);
  }

  async get(id: string) {
    const result = await axios.get(`/divisions/${id}`);
    return mapFromResponse(result.data);
  }

  async delete(id: string) {
    return axios.delete(`/divisions/${id}`);
  }

  async create(postData: any) {
    return axios.post(`/divisions`, mapToRequestBody(postData));
  }

  async edit(postData: any, id: string) {
    return axios.put(`/divisions/${id}`, mapToRequestBody(postData));
  }
}

const mapFromResponse = (division) => {
  return {
    id: division.id,
    name: division.name,
    users: division.users,
    members: division.users.filter((u) => u.type === 'member').map((u) => u.email),
  };
};

const mapToRequestBody = (division) => {
  const members = division.users.filter((u) => u.type === 'member');
  const newUsers = division.members.filter((email: string) => !members.find((m) => m.email === email));
  const deletedUsers = members
    .filter((m) => !division.members.find((email: string) => m.email === email))
    .map((u) => u.email);

  const result = {
    id: division.id,
    name: division.name,
    newUsers,
    deletedUsers,
  };

  return result;
};

const divisionsService = new DivisionsService();
export default divisionsService;
